<template>
  <SedeDesdeHasta
    titulo="Reportes de Inventario - Ventas"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    :componenteListado="componenteListado"
  />
</template>

<script>
import SedeDesdeHasta from "../components/SedeDesdeHasta.vue";
import ReporteInventarioService from "../services";

export default {
  name: "ReporteInventarioVentasLayout",
  components: {
    SedeDesdeHasta,
  },
  data() {
    return {
      componenteListado: "ListadoComprasVentas",
    };
  },
  methods: {
    servicioGetReporte: ReporteInventarioService.getReporteInventarioVentas,
    servicioGetReportePdf:
      ReporteInventarioService.getReporteInventarioVentasPdf,
  },
};
</script>
  